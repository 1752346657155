// import React, { useState, useEffect } from "react"
// import SEO from "../components/seo"
// import Header from "../components/blogHeader"
// import Footer from "../components/footer"
// import firebase from "gatsby-plugin-firebase"
// import { navigate } from "gatsby"
// import { getDates, getTimeSlots } from "../db/helpers"
// import { Link } from "gatsby"
// import Title from "../components/title"
// import { FaSpinner } from "react-icons/fa"

// // Styles
// import styles from "../styles/components/reservations.module.scss"

// const CancelReservation = () => {
//   const [name, setName] = useState("")
//   const [timeSlot, setTimeSlot] = useState("")
//   const [date, setDate] = useState("")
//   const [reservationId, setReservationId] = useState("")
//   const [dates, setDates] = useState([])
//   const [loading, setLoading] = useState(false)

//   useEffect(() => {
//     setDates(getDates())
//   }, [])

//   useEffect(() => {
//     setName(localStorage.getItem("name") || "")
//     setTimeSlot(localStorage.getItem("timeSlot") || getTimeSlots()[0])
//     setDate(localStorage.getItem("date") || getDates()[0])
//     setReservationId(localStorage.getItem("reservationId") || "")
//   }, [])

//   const cancelReservation = () => {
//     setLoading(true)
//     var databaseLocation = date + "/" + timeSlot + "/" + reservationId

//     if (!name || !timeSlot || !date || !reservationId) {
//       alert("Please fill in the form to proceed.")
//       setLoading(false)
//       return
//     }

//     // check if database location exists
//     firebase
//       .database()
//       .ref(databaseLocation)
//       .once("value")
//       .then(snapshot => {
//         // If reservation exists, delete it!
//         if (snapshot.exists()) {
//           firebase
//             .database()
//             .ref(databaseLocation)
//             .remove()
//             .then(() => {
//               console.log("Reservation has been cancelled.")
//               navigate("/reservation-cancelled")
//             })
//             .catch(e => {
//               setLoading(false)
//               console.log(
//                 "There was a problem with cancelling this reservation.",
//                 e
//               )
//             })
//           // Throw error if reservation does not exist
//         } else {
//           alert("Reservation does not exist. Please try again.")
//           setLoading(false)
//           throw new Error("Reservation does not exist.")
//         }
//       })
//   }

//   const onChangeHandler = e => {
//     switch (e.target.name) {
//       case "name":
//         setName(e.target.value)
//         break
//       case "timeSlot":
//         setTimeSlot(e.target.value)
//         break
//       case "date":
//         setDate(e.target.value)
//         break
//       case "reservationId":
//         setReservationId(e.target.value)
//         break
//       default:
//         return
//     }
//   }

//   return (
//     <>
//       <Header />
//       <SEO title="Cancel Reservation" />
//       <div className={styles.main}>
//         <div className={styles.content}>
//           <div className={styles.title}>
//             <Title text="Cancel Reservation" />
//           </div>

//           <p className={styles.msg2}>
//             If you are unable to come, please cancel the reservation so that
//             another person can use that opportunity.
//           </p>

//           <div className={styles.form}>
//             <label>
//               Full Name:
//               <input
//                 required
//                 type="text"
//                 id="name"
//                 name="name"
//                 value={name}
//                 onChange={onChangeHandler}
//               />
//             </label>

//             <label>
//               Date:
//               <select
//                 value={date}
//                 className={styles.select}
//                 onChange={onChangeHandler}
//                 name="date"
//                 id="date"
//               >
//                 {dates &&
//                   dates.map((date, i) => (
//                     <option className={styles.option} key={i} value={date}>
//                       {date}
//                     </option>
//                   ))}
//               </select>
//             </label>

//             <label>
//               Time Slot:
//               <select
//                 value={timeSlot}
//                 className={styles.select}
//                 onChange={onChangeHandler}
//                 name="timeSlot"
//               >
//                 {getTimeSlots(date).map((slot, i) => (
//                   <option className={styles.option} key={i} value={slot}>
//                     {slot}
//                   </option>
//                 ))}
//               </select>
//             </label>

//             <label>
//               Reservation ID:
//               <input
//                 required
//                 type="text"
//                 id="reservationId"
//                 name="reservationId"
//                 value={reservationId}
//                 onChange={onChangeHandler}
//               />
//             </label>

//             <p className={styles.msg}>
//               If you are having any issues with cancelling a reservation, please
//               email <a href="mailto:info@nugo.xyz">info@nugo.xyz</a> with your
//               Name, Reservation Date and Time Slot.
//             </p>
//           </div>

//           <div className={styles.btnGroup}>
//             <Link to="/reservations">
//               <button className={styles.btn2}>Go Back</button>
//             </Link>
//             <button
//               className={styles.btn}
//               onClick={cancelReservation}
//               disabled={loading}
//             >
//               {!loading ? (
//                 "Cancel Reservation"
//               ) : (
//                 <FaSpinner className={styles.spinner} />
//               )}
//             </button>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   )
// }

// export default CancelReservation

import React from "react"
import SEO from "../components/seo"
import Header from "../components/blogHeader"
import Footer from "../components/footer"
import { Link } from "gatsby"

// Styles
import styles from "../styles/pages/success.module.scss"

const ClosedPage = () => (
  <>
    <Header />
    <SEO title="Make A Reservation" />
    <div className={styles.main}>
      <div className={styles.content}>
        <h1>Just Walk In!</h1>
        <p style={{ fontSize: 14 }}>
          We have temporarily disabled the reservation system!
        </p>
        <p> Kindly walk in to our gym at your convenience. Thank you!</p>
        <Link to="/">
          <button className={styles.button}>Go Back</button>
        </Link>
      </div>
    </div>
    <Footer />
  </>
)

export default ClosedPage
